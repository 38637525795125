import { Capacitor } from '@capacitor/core';
const {
  REACT_APP_ENV,
  REACT_APP_API_URL,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_MESSAGING_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_APP_NAME,
  REACT_APP_APPLE_MERCHANT_ID,
  REACT_APP_IOS_API_URL,
  REACT_APP_ANDROID_API_URL,
  REACT_APP_CAPTCHA_KEY,
  REACT_APP_CAPTCHA2_KEY,
  REACT_APP_RECAPTCHA_KEY,
} = process.env;

const appName = REACT_APP_FIREBASE_APP_NAME as string;

export const isNative = Capacitor.isNativePlatform();
export const platform = Capacitor.getPlatform();
export const isProd = REACT_APP_ENV === 'production';

export const config = {
  apiBaseUrl: isNative
    ? platform === 'ios'
      ? REACT_APP_IOS_API_URL
      : REACT_APP_ANDROID_API_URL
    : REACT_APP_API_URL || 'https://splitmyfare-server-stage.herokuapp.com',
  appleMerchantId: REACT_APP_APPLE_MERCHANT_ID,
  firebase: {
    apiKey: REACT_APP_FIREBASE_API_KEY,
    authDomain: `${appName}.firebaseapp.com`,
    databaseURL: `https://${appName}.firebaseio.com`,
    projectId: appName,
    storageBucket: `${appName}.appspot.com`,
    messagingSenderId: REACT_APP_FIREBASE_MESSAGING_ID,
    appId: REACT_APP_FIREBASE_APP_ID,
  },
  sentry: {
    dsn: 'https://9e7b977389b44b5fb2b5830e26b8b2fb@o802830.ingest.sentry.io/5802763',
  },
  logRocket: {
    key: isNative ? 'wpr6hx/smf' : '1ronb6/s',
  },
  captcha: {
    key: REACT_APP_CAPTCHA_KEY,
    key2: REACT_APP_CAPTCHA2_KEY,
  },
  recaptcha: {
    key: REACT_APP_RECAPTCHA_KEY,
  },
  buildNumber: 13,
};
